import React, { useContext, useState } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';

const UploadquesCSVfile = ({ handleAddData }) => {
    const { endPointUrl, Token } = useContext(MyContext);
    const [csvData, setCsvData] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState(null); // New state to store selected file name

    const handleCsvUpload = async () => {
        const formData = new FormData();
        formData.append('csvFile', new Blob([Papa.unparse(csvData)], { type: 'text/csv' }));
        try {
            const response = await axios.post(
                `${endPointUrl}/uploadd-csv`,
                // { data: csvData }, // Adjust the data format as needed
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${Token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            message.success('CSV data uploaded and inserted successfully.');
            handleAddData(response.data)
            // Handle any further actions or state updates after successful upload
        } catch (error) {
            message.error('Error uploading CSV data.');
            console.error(error);
        }
    };

    

    const generateSampleCSV = async () => {
        try {
            const response = await axios.get(`${endPointUrl}/generate-questionbanksample-csv`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                responseType: 'blob',
            });

            // const csvBlob = new Blob([response.data], { type: 'text/csv' });
            // const blobURL = URL.createObjectURL(csvBlob);

            const blobURL = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = blobURL;
            link.download = 'sample.csv';
            link.click();

            // Clean up the blob URL after the download
            URL.revokeObjectURL(blobURL);

        } catch (error) {
            message.error('Error generating sample CSV.');
            console.error(error);
        }
    };

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const csvContent = event.target.result;
                const parsedData = Papa.parse(csvContent).data;
                setCsvData(parsedData);
                setSelectedFileName(file.name);
            };
            reader.readAsText(file);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv',
    });

    return (
        <div>
            <div className="drag-drop-area" {...getRootProps()}>
                {/* Visible drop area */}
                <div className="drop-zone">
                    <p>Drag and drop a CSV file here, or click to select</p>
                    <input {...getInputProps()} />
                    {/* Display selected file name */}
                    {selectedFileName && <p>Selected file: {selectedFileName}</p>}
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                    <Button onClick={generateSampleCSV} style={{ margin: "15px" }}>Download Sample CSV Format</Button>
                </span>

                {selectedFileName && (
                    <span>
                        <Button onClick={handleCsvUpload} style={{ margin: "15px" }} disabled={!csvData.length}>Upload CSV Data</Button>
                    </span>
                )}
            </div>

        </div>
    );
};

export default UploadquesCSVfile;