import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom"; // Import useNavigate from react-router-dom
import MyContext from "MyContext";
import axios from "axios";
import {
    Card,
    Button,
} from "react-bootstrap";
import { message } from "antd";

function Dispute({ ProjectDetail }) {
    const history = useHistory();
    const { Token, endPointUrl } = useContext(MyContext);
    const [disputedData, setDisputedData] = useState();
    const [disputeAttach, setDisputeAttach] = useState();
    const [alreadyRaised, setAlreadyRaised] = useState();
    const [action, setAction] = useState(ProjectDetail?.AdminAction);
    const [status, setStatus] = useState(ProjectDetail?.Status);

    const getDispute = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/IsDisputeRaised", {
                UserID: ProjectDetail?.FreelancerID,
                EmployerID: ProjectDetail?.EmployerID,
                JobID: ProjectDetail?.ProjectID
            });

            if (data.status === true) {
                setDisputedData(data.data);
                const att = data.data.Attachment.split(',');
                setDisputeAttach(att);
                setAlreadyRaised(true);
            }
        } catch (error) {
            console.log("error is dipusted", error);
        }
    }

    const handleTakeAction = async () => {
        try {
            const { data } = await axios.post(`${endPointUrl}/updateDispute`, {
                AdminAction: action,
                Status: status,
                id: ProjectDetail.id,
            });

            if (data.status) {
                message.success("Action Submitted Successfully")
                history.push(`/admin/Dispute`);
            }
        } catch (error) {
            console.log("errror", error);
        }
    }

    useEffect(() => {
        getDispute();
    }, [])


    return (
        <>
            <Card className="jobContainer">
                <h3>Raised Dispute</h3>
                {disputedData ?
                    <div className='disputed__container'>
                        <div>
                            <p className='disputeepara2' style={{ fontFamily: 'Poppins', fontWeight: '900', color: '#001', marginLeft: '1rem', marginTop: '1rem' }}>{disputedData.RaisedBy == 'Freelancer' ? `Freelancer have Raised a dispute for this project on ${new Date(disputedData.CreatedDate)}` : `Employer have Raised a dispute for this project on ${new Date(disputedData.CreatedDate)}`}</p>

                            <div style={{ marginLeft: '1rem' }}>
                                <p style={{ color: '#001', fontFamily: 'Poppins', fontWeight: 'bold', marginBottom: 0 }}>Description</p>
                            </div>
                            <div style={{ marginLeft: '1.001rem' }}>
                                <div style={{ color: '#001', marginTop: '.3rem' }} dangerouslySetInnerHTML={{ __html: disputedData.Description }} />
                            </div>

                            <div style={{ marginLeft: '1rem' }}>
                                <p style={{ color: '#001', fontFamily: 'Poppins', fontWeight: 'bold' }}>Attachment</p>
                            </div>
                            <div>
                                {
                                    disputeAttach.map((item) => {
                                        return (
                                            <div style={{ width: 80, height: 60, overflow: 'hidden', marginLeft: '1rem', cursor: 'pointer' }}>
                                                <a href={`${endPointUrl}/images/${item}`} target="_blank"><img style={{ width: '100%' }} src={`${endPointUrl}/images/${item}`} /></a>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <p className='disputee__para'> Status: {disputedData.Status}
                            </p>

                            <div>
                                <div>
                                    <textarea
                                        placeholder="Enter You Action Here..."
                                        style={{ width: "100%" }}
                                        rows={10}
                                        value={action}
                                        onChange={(e) => {
                                            setAction(e.target.value)
                                        }}
                                    >
                                    </textarea>
                                </div>
                                <div>
                                    <select
                                        value={status}
                                        onChange={(e) => {
                                            setStatus(e.target.value);
                                        }}
                                    >
                                        <option value="Resolved">Resolved</option>
                                        <option value="Pending">Pending</option>
                                        <option value="InProgress">In Progress</option>
                                    </select>
                                </div>
                                <div>
                                    <Button
                                        style={{ fill: "#07b274", marginTop: '2%' }} onClick={handleTakeAction}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>}
            </Card>
        </>
    );
}

export default Dispute;
