import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import MyContext from "MyContext";
import { message } from "antd";
import Selectant from 'react-select';
import { Country, State, City } from "country-state-city";
import employeer from "assets/img/emplyeer.png";
import freelancer from "assets/img/freelancer.png";
import { useHistory } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import add from "assets/img/add1.jpg";

// react-bootstrap components
import {
  Card,
  Form,
  Container,
  Row,
  Col
} from "react-bootstrap";

function AddFreelancer({ initialformData, Type }) {
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  // const [formData, setFormData] = useState(initialformData);
  const [formData, setFormData] = useState({
    Email: initialformData?.Email || "",
    Password: initialformData?.Password,
    Name: initialformData?.Name || "",
    Last_Name: initialformData?.Last_Name || "",
    Address: initialformData?.Address || "",
    Country: initialformData?.Country || "",
    City: initialformData?.City || "",
    State: initialformData?.State || "",
    Zip: initialformData?.Zip || "",
    Language: getLanguage(initialformData?.Language),
    Skills: initialformData?.Skills?.split(",") || "",
    About: initialformData?.About || "",
    Gender: initialformData?.Gender || "",
    Mobile: initialformData?.Mobile || "",
    rate_from: initialformData?.rate_from || 0,
    rate_type: initialformData?.rate_type || "",
    ScreenName: initialformData?.ScreenName || "",
    Weekly_Availability: initialformData?.Weekly_Availability || "",
    Monthly_Availability: initialformData?.Monthly_Availability || "",
    Time_Zone: initialformData?.Time_Zone || "",
    Contractual: initialformData?.Contractual || "",
    HourlyRate: initialformData?.HourlyRate || "",
    MonthlyRate: initialformData?.MonthlyRate || "",
    WeeklyRate: initialformData?.WeeklyRate || "",
    Bid_Balance: initialformData?.Bid_Balance || 0,
    Age: initialformData?.Age || "",
  });

  const { endPointUrl, Token } = useContext(MyContext);
  const [skillmaster, setskillmaster] = useState([]);
  const [languageMAster, setLanguagaeMaster] = useState([]);
  // Modify the state initialization for language and skills
  const [selectedLanguage, setSelectedLangauge] = useState(
    initialformData?.Language
      ? initialformData?.Language.split(",").map(lang => ({ value: lang, label: lang }))
      : []
  );

  const [selectedSkills, setSelectedSkills] = useState(
    initialformData?.Skills
      ? initialformData?.Skills.split(",").map(skill => ({ value: skill, label: skill }))
      : []
  );
  const [selectedCountry, setSelectedCountry] = useState(formData.Country);
  const [selectedState, setSelectedState] = useState(formData.State);
  const [selectedCity, setSelectedCity] = useState(formData.City);
  const [selectedGender, setSelectedGender] = useState(formData.Gender);
  const [counter, setCounter] = useState(0);
  const [userType, setUserType] = useState(Type);
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  function getLanguage(lang) {
    let languages;
    try {
      languages = JSON.parse(lang);
    } catch (error) {
      return lang;
    }
    return languages;
  }

  const [showLanguageContainer, setShowLanguagecontainer] = useState({});
  const [language, setLanguage] = useState({
    Language1: formData?.Language?.Language1 ? formData?.Language?.Language1 : "",
    Language2: formData?.Language?.Language2 ? formData?.Language?.Language2 : "",
    Language3: formData?.Language?.Language3 ? formData?.Language?.Language3 : "",
    Language4: formData?.Language?.Language4 ? formData?.Language?.Language4 : "",
    Language5: formData?.Language?.Language5 ? formData?.Language?.Language5 : "",
    Proficiency1: formData?.Language?.Proficiency1 ? formData?.Language?.Proficiency1 : "",
    Proficiency2: formData?.Language?.Proficiency2 ? formData?.Language?.Proficiency2 : "",
    Proficiency3: formData?.Language?.Proficiency3 ? formData?.Language?.Proficiency3 : "",
    Proficiency4: formData?.Language?.Proficiency4 ? formData?.Language?.Proficiency4 : "",
    Proficiency5: formData?.Language?.Proficiency5 ? formData?.Language?.Proficiency5 : "",
  });

  console.log("languase cont", language.Proficiency1)

  const history = useHistory();

  useEffect(() => {
    LanguageMAster();
    handleskillsmaster();
    setIsUpdateMode(initialformData?.Type)
  }, [])
  const handleTypeChange = (newType) => {
    setUserType(newType);
  };

  const LanguageMAster = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
        Token,
      });
      if (data && data.data && Array.isArray(data.data)) {
        const languageNames = data.data.map((item) => ({
          value: item.Name,
          label: item.Name,
        }));
        setLanguagaeMaster(languageNames);
      } else {
        console.log('Failed to fetch language. Data may be empty or not in the expected format.');
      }
    } catch (error) {
      console.log('Failed to fetch skills:', error);
    }

  };
  const handleskillsmaster = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
        Token,
      });
      if (data && data.data && Array.isArray(data.data)) {
        const skillNames = data.data.map((item) => ({
          value: item.Name,
          label: item.Name,
        }));
        setskillmaster(skillNames);
      } else {
        console.log('Failed to fetch skills. Data may be empty or not in the expected format.');
      }
    } catch (error) {
      console.log('Failed to fetch skills:', error);
    }

  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.Email == "" || formData.Password == "" || formData.Name == "" || formData.ScreenName == "") {
      return message.warning("Email, Password ,Name and Screen Name required");
    }
    try {
      await progress();
      if (isUpdateMode) {
        // If initialformData has an 'id' field, it means we are updating an existing user
        // Use the update API endpoint and pass formData and initialformData.id
        const selectedSkillsString = selectedSkills.map((skill) => skill.value).join(', ');
        const selectedLanguagesString = JSON.stringify(language);
        // console.log(formData);
        const { data } = await axios.post(
          endPointUrl + "/updateuserProfile",
          {
            Token,
            ...formData,
            id: initialformData.id,
            Skills: selectedSkillsString,
            Language: selectedLanguagesString,
            Type: userType,
            Gender: selectedGender,
            profile_progress: counter
          }

        );
        if (Type == "Freelancer") {
          navigateToFreelancer();
        }
        else {
          navigateToEmployer();
        }
        // Handle the response data as needed
        message.success('User data updated successfully.');
      }
      else {
        const selectedSkillsString = selectedSkills.map((skill) => skill.value).join(', ');
        const selectedLanguagesString = selectedLanguage.map((language) => language.value).join(', ');

        const { data } = await axios.post(endPointUrl + "/admin/addUserByAdmin", {
          Token,
          ...formData,
          Skills: selectedSkillsString,
          Language: selectedLanguagesString,
          Type: userType,
          Gender: selectedGender,
          profile_progress: counter

        });
        setFormData({
          Email: "",
          Password: "",
          Name: "",
          Last_Name: "",
          Address: "",
          Country: "",
          City: "",
          State: "",
          Zip: "",
          Language: "",
          Skills: "",
          About: "",
          Gender: "",
          Mobile: "",
          rate_from: 0,
          rate_type: "",
          Bid_Balance: 0,
          Age: '',
        })
        setSelectedCountry("")
        setSelectedState("")
        setSelectedCity("")
        setSelectedSkills([])
        setSelectedLangauge([])
        setSelectedGender("")
        // Show a success message
        message.success('User form submitted successfully.');
      }
    }
    catch (error) {
      // Handle error response or show an error message
      console.log('User Form submission failed:', error);
      message.error('Failed to submit User Form.');
    }
  };

  const progress = () => {
    if (userType === "Freelancer") {
      (setCounter((counter) => counter = 0));
      (formData?.About) ? (setCounter((counter) => counter + 10)) : (null),
        (formData?.rate_from > 0) ? (setCounter((counter) => counter + 4)) : (null),
        (formData?.rate_type) ? (setCounter((counter) => counter + 4)) : (null),
        (formData?.Skills) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Name) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Address) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Gender) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Mobile) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Language) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Email) ? (setCounter((counter) => counter + 2)) : (null),
        (formData?.City) ? (setCounter((counter) => counter + 2)) : (null),
        (formData?.State) ? (setCounter((counter) => counter + 2)) : (null),
        (formData?.Country) ? (setCounter((counter) => counter + 2)) : (null)
    } else {
      (setCounter((counter) => counter = 0));
      (formData?.About) ? (setCounter((counter) => counter + 17)) : (null),
        (formData?.Name) ? (setCounter((counter) => counter + 10)) : (null),
        (formData?.Address) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Gender) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.Mobile) ? (setCounter((counter) => counter + 10)) : (null),
        (formData?.City) ? (setCounter((counter) => counter + 7)) : (null),
        (formData?.Email) ? (setCounter((counter) => counter + 7)) : (null),
        (formData?.Language) ? (setCounter((counter) => counter + 5)) : (null),
        (formData?.State) ? (setCounter((counter) => counter + 2)) : (null),
        (formData?.Country) ? (setCounter((counter) => counter + 2)) : (null)
    }
  }

  const handleSkillSelect = (selectedOption) => {
    setSelectedSkills(selectedOption);
    setFormData({ ...formData, Skills: selectedOption })
  };
  const handleLanguageSelect = (selectedOption) => {
    setSelectedLangauge(selectedOption);
    setFormData({ ...formData, Language: selectedOption })
  };

  const navigateToFreelancer = () => {
    history.push("/admin/Freelancers");
  };
  const navigateToEmployer = () => {
    history.push("/admin/Employers");
  };


  function getMonthlyHours() {
    const option = [];
    for (let i = 1; i <= 720; i++) {
      option.push(<option>{i}</option>);
    }
    return option;
  }

  function getWeeklyHours() {
    const option = [];
    for (let i = 1; i <= 168; i++) {
      option.push(<option>{i}</option>);
    }
    return option;
  }

  function getAgeOptions() {
    const option = []
    option.push(<option value="">Select Age</option>)
    for (let i = 18; i <= 60; i++) {
      option.push(<option value={i}>{i}</option>)
    }
    return option;
  }

  const showLanguage = () => {
    return languageMAster.map((item) => {
      return <option value={item.value}>{item.label}</option>;
    });
  }

  function handleAddLanguageShow() {
    if (!showLanguageContainer.Language2) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language2: true });
    } else if (!showLanguageContainer.Language3) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language3: true });
    } else if (!showLanguageContainer.Language4) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language4: true });
    } else if (!showLanguageContainer.Language5) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language5: true });
    }
  }

  return (
    <>
      <Container fluid>
        <div className="container-fluid d-flex justify-content-between">
          <h6 className="heading-6">
            {initialformData?.id
              ? "Update"
              : Type === "Employer" || Type === "Employer"
                ? "Add Employer"
                : "Add Freelancer"}
          </h6>
        </div>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                <Card.Header style={{ backgroundColor: '#07b274' }}>
                  <Card.Title as="h4">Fill Details</Card.Title>
                  <hr></hr>
                </Card.Header>

                <Card.Body>
                  <div className="type">
                    {Type === "Employer" ?
                      <div
                        className="col-md-12"
                        style={{
                          backgroundColor: userType === "Employer" ? "#07b27361" : "",
                        }}
                        onClick={() => handleTypeChange("Employer")}
                      >
                        <img src={employeer} alt="employer" />
                        <strong>Employer</strong>
                        <span>(I want to Hire)</span>
                      </div> :
                      <div
                        className="col-md-12"
                        style={{
                          backgroundColor: userType === "Freelancer" ? "#07b27361" : "",
                        }}
                        onClick={() => handleTypeChange("Freelancer")}
                      >
                        <img src={freelancer} alt="freelancer" />
                        <strong>Freelancer</strong>
                        <span>(I am looking for work)</span>
                      </div>
                    }
                  </div>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                          name="Name"
                          placeholder="Name"
                          type="text"
                          value={formData.Name}
                          // onChange={handleChange}
                          onChange={(e) => setFormData({ ...formData, Name: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>
                          Last Name
                        </label>
                        <Form.Control
                          name="Last_Name"
                          value={formData.Last_Name}
                          placeholder="Last_Name"
                          type="text"
                          // onChange={handleChange}
                          onChange={(e) => setFormData({ ...formData, Last_Name: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md={isUpdateMode ? "12" : "6"}>
                      <Form.Group>
                        <label>
                          Email
                        </label>
                        <Form.Control
                          name="Email"
                          value={formData.Email}
                          placeholder="Email"
                          type="email"
                          // onChange={handleChange}
                          onChange={(e) => setFormData({ ...formData, Email: e.target.value })}
                          disabled={isUpdateMode}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    { }
                    {!isUpdateMode ?
                      <Col className="pr-1" md="6">
                        <Form.Group>
                          <label>Password</label>
                          <Form.Control
                            name="Password"
                            placeholder="Password"
                            type="text"
                            value={formData.Password}
                            // onChange={handleChange}
                            onChange={(e) => setFormData({ ...formData, Password: e.target.value })}
                          >
                          </Form.Control>
                        </Form.Group>
                      </Col> :
                      null}

                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Screen Name</label>
                        <Form.Control
                          name="ScreenName"
                          value={formData.ScreenName}
                          placeholder="ScreenName"
                          type="text"
                          onChange={(e) => setFormData({ ...formData, ScreenName: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label htmlFor="Age">
                          Age<span className="asterisk">*</span>
                        </label>
                        {/* <small style={{ color: "#ec1c24" }}>{errors.Age}</small> */}
                        <select
                          style={{ width: '100%' }}
                          name="Age"
                          value={formData.Age}
                          // className={errors.Age != "" ? "errorborder" : ""}
                          placeholder="Enter Your Age"
                          // onChange={(e) => {
                          //   const inputValue = e.target.value.trim();
                          //   const firstWord = inputValue.split(' ')[0];
                          //   setedit({ ...edit, Age: firstWord });
                          // }}

                          onChange={(e) => {
                            const input = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 2);
                              setFormData({ ...formData, Age: e.target.value })
                          }}
                        >
                          {getAgeOptions()}
                        </select>
                      </Form.Group>
                    </Col>

                    {Type !== "Employer" ?
                      <Col className="pr-1" md="6">
                        <Form.Group>
                          <label>Skills</label>
                          <Selectant
                            name="Skills"
                            options={skillmaster}
                            isMulti
                            onChange={handleSkillSelect}
                            value={selectedSkills}
                          />
                        </Form.Group>
                      </Col> : null}
                  </Row>

                  <Row style={{ marginTop: '2%' }}>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Weekly (in Hours):</label>
                        <br />
                        <select
                          style={{ width: "100%", height: "100%", padding: "10px", borderColor: "#d4d3d3", outline: "none" }}
                          value={formData.Weekly_Availability}
                          onChange={(e) => {
                            if (e.target.value <= 168) {
                              setFormData({
                                ...formData,
                                Weekly_Availability: e.target.value,
                              });
                            }
                          }}
                        >
                          <option>Select Hours</option>
                          {getWeeklyHours()}
                        </select>
                      </Form.Group>
                    </Col>

                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label for="monthly">Monthly (in Hours):</label>
                        <select
                          style={{ width: "100%", height: "100%", padding: "10px", borderColor: "#d4d3d3", outline: "none" }}
                          name="monthly"
                          value={formData.Monthly_Availability}
                          placeholder="Enter hours"
                          onChange={(e) => {
                            if (e.target.value <= 720) {
                              setFormData({
                                ...formData,
                                Monthly_Availability: e.target.value,
                              });
                            }
                          }}

                        >
                          <option>Select Hours</option>
                          {getMonthlyHours()}
                        </select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '2%' }}>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Time Zone</label>
                        <TimezoneSelect
                          value={selectedTimezone}
                          onChange={(val) => {

                            setFormData({
                              ...formData,
                              Time_Zone: val.label,
                            });
                            setSelectedTimezone(val);
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label for="availability">
                          Open for contractual position / Projects:
                        </label>
                        <select
                          style={{ width: "100%", height: "100%", padding: "10px", borderColor: "#d4d3d3", outline: "none" }}
                          name="availability"
                          id="availability"
                          value={formData.Contractual}
                          onChange={(e) =>
                            setFormData({ ...formData, Contractual: e.target.value })
                          }
                        >
                          <option value="volvo">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </Form.Group>
                    </Col>
                  </Row>

                  {userType === "Freelancer" ?
                    <Row style={{ marginTop: '2%' }}>
                      <Col className="pr-1" md="4">
                        <Form.Group>
                          <label htmlFor="Hourly_Rate">Hourly ($)</label>
                          <Form.Control
                            type="number"
                            name="Hourly_Rate"
                            id="Hourly_Rate"
                            value={formData.HourlyRate}
                            placeholder="Hourly Rate"
                            onChange={(e) => setFormData({ ...formData, HourlyRate: e.target.value })}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-1" md="4">
                        <Form.Group>
                          <label htmlFor="Weekly_Rate">Weekly ($)</label>
                          <Form.Control
                            type="number"
                            name="Weekly_Rate"
                            id="Weekly_Rate"
                            value={formData.WeeklyRate}
                            placeholder="Weekly Rate"
                            onChange={(e) => setFormData({ ...formData, WeeklyRate: e.target.value })}
                          >
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col className="pl-1" md="4">
                        <Form.Group>
                          <label htmlFor="Monthly_Rate">Monthly ($)</label>
                          <Form.Control
                            type="number"
                            name="Monthly_Rate"
                            id="Monthly_Rate"
                            placeholder='Monthly Rate'
                            value={formData.MonthlyRate}
                            onChange={(e) => {
                              setFormData({ ...formData, MonthlyRate: e.target.value });
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                    </Row> : ""
                  }

                  <Row style={{ marginTop: '2%' }}>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Mobile</label>
                        <Form.Control
                          name="Mobile"
                          placeholder="Mobile"
                          type="Number"
                          value={formData.Mobile}
                          // onChange={handleChange}
                          onChange={(e) => setFormData({ ...formData, Mobile: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <div className="gender">
                          <label>Gender</label>
                          <div >
                            <label>
                              <input
                                type="radio"
                                name="gender"
                                value="male"
                                checked={selectedGender === "male"}
                                onChange={(e) => setSelectedGender(e.target.value)}
                                defaultChecked={formData.Gender == "male"}
                              // checked={formData.Gender == "male"}
                              // onChange={(e) => {
                              //   setSelectedGender(e.target.value)
                              //   setFormData({ ...formData, Gender: e.target.value })
                              // }
                              // }

                              />
                              <span > Male</span>
                            </label>
                            <label style={{ marginLeft: '10px' }}>
                              <input
                                type="radio"
                                name="gender"
                                value="Female"
                                checked={selectedGender === "Female"}
                                onChange={(e) => setSelectedGender(e.target.value)}
                                defaultChecked={formData.Gender == "Female"}
                              // onChange={(e) => setFormData({ ...formData, Gender: e.target.value })}
                              />
                              <span> Female </span>
                            </label>
                            <label style={{ marginLeft: '10px' }}>
                              <input
                                type="radio"
                                name="gender"
                                value="other"
                                checked={selectedGender === "other"}
                                onChange={(e) => setSelectedGender(e.target.value)}
                                defaultChecked={formData.Gender == "other"}
                              // onChange={(e) => setFormData({ ...formData, Gender: e.target.value })}
                              />
                              <span> Other </span>
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '2%' }}>
                    <Col className="pr-1" md="6">
                      <label htmlFor="Language">
                        Language<span className="asterisk">*</span>
                      </label>
                      <select
                        style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                        value={language.Language1}
                        onChange={(e) =>
                          setLanguage({
                            ...language,
                            Language1: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Language</option>
                        {showLanguage()}
                      </select>
                    </Col>

                    <Col className="pl-1" md="6">
                      <label htmlFor="proficiency">
                        Proficiency<span className="asterisk">*</span>
                      </label>
                      <select
                        value={language.Proficiency1}
                        style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                        onChange={(e) =>
                          setLanguage({
                            ...language,
                            Proficiency1: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Proficiency</option>
                        <option value="Expert">Expert</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Beginner">Beginner</option>
                      </select>
                    </Col>
                  </Row>

                  {showLanguageContainer.Language2 ?
                    <Row style={{ marginTop: '2%' }}>
                      <Col className="pr-1" md="6">
                        <select
                          style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                          value={language.Language2}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Language2: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Language</option>
                          {showLanguage()}
                        </select>
                      </Col>

                      <Col className="pl-1" md="6">
                        <select
                          value={language.Proficiency2}
                          style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency2: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </Col>
                    </Row> :
                    <></>}

                  {showLanguageContainer.Language3 ?
                    <Row style={{ marginTop: '2%' }}>
                      <Col className="pr-1" md="6">
                        <div className="language">
                          <select
                            style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                            value={language.Language3}
                            onChange={(e) =>
                              setLanguage({
                                ...language,
                                Language3: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Language</option>
                            {showLanguage()}
                          </select>
                        </div>
                      </Col>

                      <Col className="pl-1" md="6">
                        <select
                          value={language.Proficiency3}
                          style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency3: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </Col>
                    </Row> :
                    <></>}

                  {showLanguageContainer.Language4 ?
                    <Row style={{ marginTop: '2%' }}>
                      <Col className="pr-1" md="6">
                        <select
                          style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                          value={language.Language4}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Language4: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Language</option>
                          {showLanguage()}
                        </select>
                      </Col>

                      <Col className="pl-1" md="6">
                        <select
                          value={language.Proficiency4}
                          style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency4: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </Col>
                    </Row>
                    : <></>}

                  {showLanguageContainer.Language5 ?
                    <Row style={{ marginTop: '2%', padding: '0px' }}>
                      <Col className="pr-1" md="6">
                        <select
                          style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                          value={language.Language5}
                          // className={errors.Language != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Language5: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Language</option>
                          {showLanguage()}
                        </select>
                      </Col>

                      <Col className="pl-1" md="6">
                        <select
                          value={language.Proficiency5}
                          style={{ width: "100%", borderColor: "#d4d3d3", outline: "none" }}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency5: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </Col>
                    </Row> :
                    <></>}

                  <Row style={{ marginTop: '2%' }}>
                    <Col>
                      <div>
                        <strong style={{ display: "flex", alignItems: "center" }}>
                          Add Language{" "}
                          <img src={add} alt="" width="30px" onClick={handleAddLanguageShow} />
                        </strong>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>About</label>
                        <Form.Control
                          name="About"
                          placeholder="About"
                          as="textarea"
                          rows={3}
                          cols={50}
                          value={formData.About}
                          onChange={(e) => setFormData({ ...formData, About: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Country</label>

                        <Selectant
                          options={Country.getAllCountries()}
                          getOptionLabel={(options) => {
                            return options.name;
                          }}
                          getOptionValue={(options) => {
                            return options.name;
                          }}
                          value={
                            Country.getAllCountries().find((country) => country.name === formData.Country) || null
                          }
                          // onChange={(selectedOption) => {setSelectedCountry(selectedOption) }}
                          onChange={(selectedOption) => {
                            // console.log("Selectoption", selectedOption)
                            setSelectedCountry(selectedOption);
                            setFormData({ ...formData, Country: selectedOption.name });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>State</label>
                        <Selectant
                          options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          value={
                            State.getAllStates().find((state) => state.name === formData.State) || null
                          }
                          // onChange={(selectedOption) =>setSelectedState(selectedOption)}
                          onChange={(selectedOption) => {
                            setSelectedState(selectedOption);
                            setFormData({ ...formData, State: selectedOption.name });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>City</label>
                        <Selectant
                          options={City.getCitiesOfState(
                            selectedState?.countryCode,
                            selectedState?.isoCode
                          )}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          value={
                            City.getAllCities().find((city) => city.name === formData.City) || null
                          }
                          // onChange={(selectedOption) => setSelectedCity(selectedOption)}
                          onChange={(selectedOption) => {
                            setSelectedCity(selectedOption);
                            setFormData({ ...formData, City: selectedOption.name });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label> Zip </label>
                        <Form.Control
                          name="Zip"
                          placeholder="Zip"
                          type="text"
                          value={formData.Zip}
                          // onChange={handleChange}
                          onChange={(e) => setFormData({ ...formData, Zip: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Address</label>
                        <Form.Control
                          name="Address"
                          placeholder="Address"
                          as="textarea" // Use 'as' prop to specify it's a textarea
                          rows={3}      // Specify the number of rows
                          cols={50}     // Specify the number of columns
                          value={formData.Address}
                          // onChange={handleChange}
                          onChange={(e) => setFormData({ ...formData, Address: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    {/* {Type === "Freelancer" ? <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Bid Balance</label>
                        <Form.Control
                          type='number'
                          name="Bid_Balance"
                          placeholder="Bid Balance"
                          value={formData.Bid_Balance}
                          onChange={(e) => setFormData({ ...formData, Bid_Balance: e.target.value })}
                        ></Form.Control>
                      </Form.Group>
                    </Col> : <></>} */}
                  </Row>


                  {userType === "Freelancer" && (

                    <Row>

                      {/* <Col className="pl-1" md="6">
                        <Form.Group>
                          <label>Technology</label>
                          <Form.Control
                            name="Technology"
                            placeholder="Technology"
                            type="text"
                            value={formData.Technology}
                            onChange={handleChange}
                          ></Form.Control>
                        </Form.Group>
                      </Col> */}
                    </Row>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      className="btn mb-1 btn-lg mr-3"
                      type="submit"
                      variant="info"
                      style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px", cursor: "pointer" }}
                    >
                      {isUpdateMode ? "Update" : "Submit"}
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form >
      </Container >

    </>
  )
};

export default AddFreelancer;