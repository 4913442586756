import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import PendingVerification from "./PendingVerification";
import RejectedVerification from "./RejectedVerification";
import VerifiedVerification from "./VerifiedVerification";
import Profile from "assets/img/default-avatar.png"
import "assets/css/demo.css"
import { BsArrowLeftShort } from "react-icons/bs"
import MyContext from "MyContext";

import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";

import ReactPaginate from 'react-paginate';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import AddEmployer from "./Userform";
import UploadCSVfile from "./UploadCSVfile";
import { useHistory } from "react-router-dom";
import DisplayProduct from "./DisplayProduct";
import ProductInerested from "./ProductInterested";



function Verification() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [activeCard, setActiveCard] = useState("Pending");
    const [showmodel, setshowModel] = useState(false);
    const [productRequestCount, setProductRequestCount] = useState('');
    const [productCount, setProductCount] = useState('');
    const [show, setShow] = useState(true)
    const history = useHistory();

    const count = async () => {
        try {
            const { data } = await axios.get(endPointUrl + "/countProductRequest", {
                Token,
            });
            setProductRequestCount(data.data);
        } catch {
            console.log("counts is not working");
        }
    };


    const countProduct = async () => {
        try {
            const { data } = await axios.get(endPointUrl + "/countProduct", {
                Token,
            });
            setProductCount(data.data);
        } catch {
            console.log("counts is not working");
        }
    };

    useEffect(() => {
        count();
        countProduct();
    }, []);


    const navigateToProduct = () => {
        setShow(!show);
    };
    const navigateToInterested = () => {
        setShow(!show);
    };

    return (
        <>
            <>
                <Container fluid>
                    <Row>
                        <Col lg="6" sm="12">
                            <Card className={`card-stats ${show === true ? "active" : ""}`} onClick={navigateToProduct} style={{ borderBottom: show === true ? "4px solid #07b273" : "" }}>
                                <Card.Body>
                                    <Row>
                                        <Col xs="4">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart text-warning"></i>
                                            </div>
                                        </Col>
                                        <Col sm="7">
                                            <div className="numbers">
                                                <p className="card-category">Products</p>
                                                <Card.Title as="h3">{productCount}</Card.Title>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6" sm="12">
                            <Card className={`card-stats ${show === false ? "active" : ""}`} onClick={navigateToInterested} style={{ borderBottom: show === false ? "4px solid #07b273" : "" }}>
                                <Card.Body>
                                    <Row>
                                        <Col xs="4">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart text-warning"></i>
                                            </div>
                                        </Col>
                                        <Col sm="7">
                                            <div className="numbers">
                                                <p className="card-category">Product Request</p>
                                                <Card.Title as="h3">{productRequestCount}</Card.Title>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>
                </Container>



                <Container fluid >
                    {show === true && <DisplayProduct />}
                    {show === false && <ProductInerested />}

                </Container>
            </>
        </>
    );
}

export default Verification;
