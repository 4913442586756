import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs"
import BiddingCard from "./BiddingCard";
import Profile from "assets/img/default-avatar.png"
import { useLocation } from "react-router-dom";

import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import moment from 'moment';

import { Collapse } from "antd";
const { Panel } = Collapse;

import ReactPaginate from 'react-paginate';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import EditJob from "./editJob";
import Dispute from "./Dispute";
import { useHistory } from "react-router-dom"; // Import useNavigate from react-router-dom
import Master from "./Masterss";

const DisputeDetail = () => {
    const history = useHistory();
    const { endPointUrl, Token } = useContext(MyContext);
    const location = useLocation();
    const [project, setProject] = useState('');
    const [freelancer, setFreelancer] = useState('');
    const [employer, setEmployer] = useState('');
    const Id = location.pathname.split('/').pop();
    const [milestone, setmilestone] = useState([]);
    const [projectDetail, setProjectDetail] = useState('')
    const [loading, setLoading] = useState(true);

    const Jobs = async (Id) => {
        try {
            const { data } = await axios.post(endPointUrl + "/getDisputeDetailByID", {
                id: Id,
            });
            setProjectDetail(data.data);
            getMilestoneByProjectId(data?.data?.ProjectID)
            setProject(data?.data?.job);
            setEmployer(data?.data?.employer);
            setFreelancer(data?.data?.freelancer);
            setLoading(false);
        } catch {
            console.log("get all details projects is not working");
            setLoading(false);
        }
    };


    const getMilestoneByProjectId = async (P_ID) => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/getMilestoneByProjectID", { P_ID: P_ID })
            setmilestone(data.data);
        } catch (error) {
            console.log("Error Fetch Milestone by project id", error);
        }
    }

    const navigateToFinance = () => {
        window.history.back();
    };

    useEffect(() => {
        Jobs(Id);
    }, []);

    const handleEmployerClick = (id) => {
        history.push(`/admin/Employer-Details/${id}`)
    }

    const handleFreelancerClick = (id) => {
        history.push(`/admin/Freelancer-Details/${id}`)
    }

    const handleProjectClick = (id) => {
        history.push(`/admin/Project-Details/${id}`)
    }


    return (
        <>
            <Container fluid>
                <div className="container-fluid d-flex align-items-center">
                    <span className="close profile" onClick={navigateToFinance}>
                        <BsArrowLeftShort style={{ fill: "#07b274", margin: "-20px 10px 0 -10px" }} />
                    </span>
                    <h6 className="heading-6" style={{ padding: "20px 0 20px 0" }}>Dispute Detail</h6>
                </div>
                <Row>
                    <Col md="12">

                        {projectDetail ? <Card>
                            <Dispute ProjectDetail={projectDetail} />
                        </Card> : <></>}
                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>


                            <Card.Body>
                                <div className="about">
                                    <h6>Project Detail</h6>
                                    <h3><span onClick={() => handleProjectClick(projectDetail?.ProjectID)} style={{ cursor: 'pointer' }}>{project?.Title}</span></h3>
                                    <span dangerouslySetInnerHTML={{ __html: project?.Description }}></span>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Project Type -</span> <span>{project?.ProjectType}</span>
                                    </div>
                                    <div>

                                        <span style={{ fontWeight: 'bold' }}>Budget Minimum -</span> <span>{project?.Budget_From}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Budget Maximum -</span> <span>{project?.Budget_To}</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>


                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>
                            <Card.Body>
                                <div className="about">
                                    <h6>Employer Detail</h6>
                                    <h3><span onClick={() => handleEmployerClick(projectDetail?.EmployerID)} style={{ cursor: "pointer" }}> {employer?.Name} {employer?.Last_Name}</span></h3>
                                    <span dangerouslySetInnerHTML={{ __html: employer?.About }}></span>
                                    <div style={{ marginTop: '2%' }}>
                                        <span style={{ fontWeight: 'bold' }}>Mobile Number -</span> <span>{employer?.Mobile}</span>
                                    </div>
                                    <div>

                                        <span style={{ fontWeight: 'bold' }}>Email ID -</span> <span>{employer?.Email}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Screen Name -</span> <span>{employer?.ScreenName}</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>
                            <Card.Body>
                                <div className="about">
                                    <h6>Freelancer Detail</h6>

                                    <h3>
                                        <span onClick={() => handleFreelancerClick(projectDetail?.FreelancerID)} style={{ cursor: "pointer" }}>{freelancer?.Name} {freelancer?.Last_Name}</span>
                                    </h3>
                                    <span dangerouslySetInnerHTML={{ __html: freelancer?.About }}></span>
                                    <div style={{ marginTop: '2%' }}>
                                        <span style={{ fontWeight: 'bold' }}>Mobile Number -</span> <span>{freelancer?.Mobile}</span>
                                    </div>
                                    <div>

                                        <span style={{ fontWeight: 'bold' }}>Email ID -</span> <span>{freelancer?.Email}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Screen Name -</span> <span>{freelancer?.ScreenName}</span>
                                    </div>
                                    <div>
                                        <Master milestone={milestone} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>



                    </Col>

                </Row>
            </Container>


        </>
    )
}

export default DisputeDetail;