import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MyContext from "MyContext";
import { Button, Container, Divider, Typography, Box, Paper } from '@mui/material';
import { useHistory } from "react-router-dom";
import { message } from "antd";

const PaymentConfirmation = () => {
    const [button, setbutton] = useState(false);
    const [name, setName] = useState('')
    const [actualPaid, setActualPaid] = useState('');
    const [PM_ID, setPM_ID] = useState();
    const [bankname, setbankname] = useState('');
    const [accountnum, setaccountnum] = useState('');
    const [accountname, setaccountname] = useState('');
    const [code, setcode] = useState("");
    const [bankcountry, setbankcountry] = useState('');
    const location = useLocation();
    const Id = location.pathname.split('/').pop();
    const [detail, setAllDetails] = useState([]);
    const { endPointUrl, Token } = useContext(MyContext);
    const history = useHistory();

    const getMilestoneProject = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/project/getProjectMilestoneByID", {
                Token, PM_ID: Id
            });
            setAllDetails(data.data);
            setName(`${data.data[0].Name} ${data.data[0].Last_Name}`)
            setActualPaid(data.data[0].FreelancerActualPaid);
            setPM_ID(data.data[0].PM_ID)
            setaccountnum(data.data[0].Account_Number);
            setaccountname(data.data[0].Account_Name);
            setbankname(data.data[0].Bank_Name)
            setcode(data.data[0].IFSC_CODE)
            setbankcountry(data.data[0].BANK_COUNTRY)

        } catch (error) {
            console.log("get milestone is not working", error);
        }
    }

    useEffect(() => {
        getMilestoneProject()
    }, [])

    const updateconfirmpayment = async () => {

        try {
            const { data } = await axios.post(endPointUrl + "/project/updatemilestone", {
                Token,
                PM_ID,
                TransferStatus: "Released",
            });
            message.success("Payment Released Succesfully");
            history.push("/admin/Pending-Bank-Status")
        } catch (error) {

            message.error("An error occurred.");
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ p: 4, mt: 5 }}>
                <Typography variant="h5" align="center" color="primary" gutterBottom>
                    Payment Confirmation
                </Typography>
                <Divider sx={{ mb: 3 }} />
                <Box sx={{ mb: 2 }}>
                    <Typography variant="body1"><strong>Name:</strong> {name}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>Bank Details</Typography>
                    <Typography variant="body1"><strong>Bank Name:</strong> {bankname}</Typography>
                    <Typography variant="body1"><strong>Account Name:</strong> {accountname}</Typography>
                    <Typography variant="body1"><strong>Account Number:</strong> {accountnum}</Typography>
                    <Typography variant="body1"><strong>IFSC Code:</strong> {code}</Typography>
                    <Typography variant="body1"><strong>Bank Country:</strong> {bankcountry}</Typography>
                </Box>
                <Typography variant="body1" align="center" sx={{ textDecoration: 'underline', color: 'grey', mb: 3 }}>
                    Payable Amount: {actualPaid}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" style={{ background: "#07B273" }} onClick={updateconfirmpayment}>
                        Confirm
                    </Button>
                </Box>
            </Paper>
        </Container>
    )
}


export default PaymentConfirmation;