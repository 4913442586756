
import React, { useState, useContext, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '.././assets/css/testimonial.css';
import axios from "axios";
import MyContext from "../MyContext";
import PaginationSection from './PaginationSection';
import Switch from 'react-switch'; // Import the react-switch component
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillEdit, AiFillDelete } from "react-icons/ai";

import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import {
    Card,
    Table,
    Button,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";



function Testimonial() {
    const [formData, setFormData] = useState({
        GlobalReview_Name: '',
        GlobalReview_Date: '',
        GlobalReview_Review: '',
        Stars: '',
        GlobalReview_Review_Status: "InActive",
    });
    const { endPointUrl, Token } = useContext(MyContext);
    const [testimonials, setTestimonials] = useState([]);
    const [testimonialsPendingCount, setTestimonialsPendingCount] = useState(0);
    const [testimonialsActiveCount, setTestimonialsActiveCount] = useState(0);
    const [testimonialsInActiveCount, setTestimonialsInActiveCount] = useState(0);
    const [activeCard, setActiveCard] = useState("Pending");
    const [showForm, setShowForm] = useState("");
    const [isPublished, setIsPublished] = useState(false); // State to handle the published status


    const handleshowform = async (value) => {
        setFormData({
            GlobalReview_Name: '',
            GlobalReview_Date: '',
            GlobalReview_Review: '',
            Stars: '',
            GlobalReview_Review_Status: 'InActive',
        });


        setShowForm(value);
        setIsPublished(false)
    }

    const getTestimonials = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/globalReviewfindAll", { Token });
            if (data.data) {
                console.log(data.data);


                const activeTestimonials = data.data.filter(testimonial => testimonial.GlobalReview_Review_Status === activeCard);

                setTestimonials(activeTestimonials);

                const activeCount = data.data.filter(testimonial => testimonial.GlobalReview_Review_Status === "Active").length;
                const inActiveCount = data.data.filter(testimonial => testimonial.GlobalReview_Review_Status === "InActive").length;
                const pendingCount = data.data.filter(testimonial => testimonial.GlobalReview_Review_Status !== "Active" && testimonial.GlobalReview_Review_Status !== "InActive").length;

                // Update state for the counts
                setTestimonialsActiveCount(activeCount);
                setTestimonialsInActiveCount(inActiveCount);
                setTestimonialsPendingCount(pendingCount);
            }
        } catch (err) {
            console.log(err);
        }
    };


    const addTestimonial = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(endPointUrl + "/admin/globalReviewCreate", {
                Token,
                ...formData
            });
            if (response.data.data) {
                setTestimonials([...testimonials, response.data.data]);
                setFormData({
                    GlobalReview_Name: '',
                    GlobalReview_Date: '',
                    GlobalReview_Review: '',
                    Stars: '',
                    GlobalReview_Review_Status: "InActive",
                });

                alert("Testimonial Added Successfully");
                setIsPublished(false)


            } else {
                // Handle error if needed
                console.log("Error adding testimonial:", response.data.message);
            }
        } catch (error) {
            console.log("Error adding testimonial:", error);
        }
    }

    const hadndleeditmodel = async (GId) => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/globalReviewfindById", { Token, GId });
            if (data) {
                setShowForm("Update");

                // Store the data in formData
                setFormData(data.data);

                // Extract the date and update GlobalReview_Date
                const globalReviewDate = data.data.GlobalReview_Date.slice(0, 10);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    GlobalReview_Date: globalReviewDate,
                }));

                // Check and update GlobalReview_Review_Status
                if (data.data.GlobalReview_Review_Status === "Active") {
                    setIsPublished(true);
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        GlobalReview_Review_Status: "Active",
                    }));
                } else {
                    setIsPublished(false);
                }
            }
        } catch (err) {
            console.error("Error while handling edit model:", err);
        }
    };


    const editTestimonial = async (e, GId) => {
        e.preventDefault();
        try {
            const response = await axios.post(endPointUrl + "/admin/globalReviewUpdate", {
                Token,
                ...formData,
            });

            if (response?.data?.data) {
                setTestimonials((prevTestimonials) => {
                    const updatedTestimonials = prevTestimonials.map((testimon) => {
                        if (testimon.GlobalReview_ID === GId && testimon.GlobalReview_Review_Status != formData.GlobalReview_Review_Status) {
                            if (activeCard === "Pending") {
                                setTestimonialsPendingCount((prev) => prev - 1);
                            } else if (activeCard === "Active") {
                                setTestimonialsActiveCount((prev) => prev - 1);
                            } else {
                                setTestimonialsInActiveCount((prev) => prev - 1);
                            }

                            if (formData.GlobalReview_Review_Status === "Pending") {
                                setTestimonialsPendingCount((prev) => prev + 1);
                            } else if (formData.GlobalReview_Review_Status === "Active") {
                                setTestimonialsActiveCount((prev) => prev + 1);
                            } else {
                                setTestimonialsInActiveCount((prev) => prev + 1);
                            }
                            return null;

                        } else if (testimon.GlobalReview_ID === GId) {
                            return formData;
                        } else {
                            return testimon;
                        }
                    }).filter(Boolean);

                    return updatedTestimonials;
                });
                setShowForm("");

                // Clear the form or perform other necessary actions
                setFormData({
                    GlobalReview_Name: '',
                    GlobalReview_Date: '',
                    GlobalReview_Review: '',
                    Stars: '',
                    GlobalReview_Review_Status: "InActive",
                });
            } else {
                // Handle error if needed
                console.log("Error editing testimonial:", response.data.message);
            }
        } catch (error) {
            console.log("Error editing testimonial:", error);
        }
    };


    const deleteTestimonial = async (e, GId) => {
        e.preventDefault();
        try {
            if (activeCard === "Pending") {
                setTestimonialsPendingCount((prev) => prev - 1);
            } else if (activeCard === "Active") {
                setTestimonialsActiveCount((prev) => prev - 1);
            } else {
                setTestimonialsInActiveCount((prev) => prev - 1);
            }
            // Make an API call to delete the testimonial
            const response = await axios.post(endPointUrl + "/admin/globalReviewDelete", { Token, GId });
            if (response?.data?.data) {
                // Filter out the deleted testimonial from the state

                setTestimonials((prevTestimonials) =>
                    prevTestimonials.filter((testimonial) => testimonial.GlobalReview_ID !== GId)
                );
            } else {
                console.error("Error deleting testimonial:", response.data.message);
            }
            setFormData({
                GlobalReview_Name: '',
                GlobalReview_Date: '',
                GlobalReview_Review: '',
                Stars: '',
                GlobalReview_Review_Status: "InActive",
            });

        } catch (error) {
            console.error("Error deleting testimonial:", error);
        }
    };

    const handleStatusChange = (checked) => {
        setIsPublished(checked);
        setFormData((prevFormData) => ({
            ...prevFormData,
            GlobalReview_Review_Status: checked ? "Active" : "InActive",
        }));
    };


    const data = React.useMemo(() => testimonials, [testimonials]);
    const columns = React.useMemo(
        () => [
            {
                Header: "S.No.",
                accessor: (row, index) => index + 1,
            },
            {
                Header: "Name",
                accessor: "GlobalReview_Name",
                width: "10%"
            },
            {
                Header: "Testimonial",
                accessor: "GlobalReview_Review",
                width: "60%"
            },
            {
                Header: "Stars",
                accessor: "Stars",
            },
            {
                Header: "Status",
                accessor: "GlobalReview_Review_Status",
                Cell: ({ value }) => value == "Pending" ? "Pending" : value == "Active" ? "Active" : "InAcive",
            },
            {
                Header: "Action",
                accessor: "GlobalReview_ID",
                Cell: ({ row }) => (
                    <div style={{ display: "flex" }}>
                        <span
                            // onClick={() => editTestimonial(row.original.GlobalReview_ID)}
                            onClick={() => hadndleeditmodel(row.original.GlobalReview_ID)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <AiFillEdit style={{ fill: "#07b274", height: '1.4em', width: "1.4em" }} />
                        </span>
                        {/* "Delete" icon */}
                        <span
                            onClick={(e) => deleteTestimonial(e, row.original.GlobalReview_ID)}
                            style={{ cursor: "pointer" }}
                        >
                            <AiFillDelete style={{ fill: "red", height: '1.4em', width: "1.4em" }} />
                        </span>
                        {/* <span
                           
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            Edit
                        </span> */}
                        {/* <span
                           
                            style={{ cursor: "pointer" }}
                        >
                            Delete
                        </span> */}
                    </div>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        getTestimonials();
    }, [activeCard]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page: tablePage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;

    return (
        <Container fluid>
            <Row>
                {/* <h1 style={{ width: "100%" }}>Testimonials</h1> */}
                <Col md="12">
                    {showForm === "Add" ?
                        <>
                            <Container className='addskilltest' fluid>
                                <div className="container-fluid d-flex">
                                    <span className="close profile" onClick={() => setShowForm("")}>
                                        <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px" }} />
                                    </span>
                                    <h6 className="heading-6">Add Testimonial</h6>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                                            <Card.Header style={{ backgroundColor: '#07b274' }}>
                                                <Card.Title as="h4">Fill Details</Card.Title>
                                                <hr></hr>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form onSubmit={(e) => addTestimonial(e)}>
                                                    <Row>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label htmlFor="name">Name</label>
                                                                <Form.Control
                                                                    placeholder="Enter name"
                                                                    type="text"
                                                                    name="name"
                                                                    value={formData.GlobalReview_Name}
                                                                    onChange={(e) => setFormData({ ...formData, GlobalReview_Name: e.target.value })}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label htmlFor="date">Published Date</label>
                                                                <Form.Control
                                                                    placeholder="Date"
                                                                    type="date"
                                                                    name="date"
                                                                    value={formData.GlobalReview_Date}
                                                                    onChange={(e) => setFormData({ ...formData, GlobalReview_Date: e.target.value })}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1 w-full" md="12">
                                                            <Form.Group>
                                                                <label htmlFor="review">Review</label>
                                                                <Form.Control
                                                                    placeholder="Enter your review here"
                                                                    as="textarea"
                                                                    name="review"
                                                                    value={formData.GlobalReview_Review}
                                                                    onChange={(e) => setFormData({ ...formData, GlobalReview_Review: e.target.value })}
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                  
                                                        <Col className="pr-1" md="6">
                                                            <Form.Group>
                                                                <label htmlFor="rating">Rating</label>
                                                                <Form.Control
                                                                    placeholder="Enter Rating"
                                                                    type="number"
                                                                    name="rating"
                                                                    value={formData.Stars}
                                                                    onChange={(e) => setFormData({ ...formData, Stars: e.target.value })}
                                                                    min="0"        
                                                                    max="5"
                                                                    step="1"   
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="pl-1" md="6" style={{ display: 'flex', alignItems: 'center' }} >
                                                            <Form.Group>
                                                                <label style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "30px" }}>Status</label>
                                                                {/* Use the react-switch for the sliding switch */}
                                                                <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "50px" }}>
                                                                    <Switch
                                                                        onChange={handleStatusChange}
                                                                        checked={isPublished}
                                                                        onColor="#86d3ff"
                                                                        onHandleColor="#07b274"
                                                                        handleDiameter={20}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    />
                                                                    <span style={{ marginLeft: '10px' }}>
                                                                        {isPublished ? 'Active' : 'InActive'}
                                                                    </span>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>                                                </Row>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button
                                                            className="btn-fill pull-right"
                                                            type="submit"
                                                            variant="info"
                                                            style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                        :
                        showForm === "Update" ?
                            <>

                                <Container className='addskilltest' fluid>

                                    <div className="container-fluid d-flex">
                                        <span className="close profile " onClick={() => setShowForm("")}>
                                            <BsArrowLeftShort style={{ fill: "#07b274", margin: "0 0 15px 5px" }} />
                                        </span>
                                        <h6 className="heading-6">Update Testimonial</h6>
                                    </div>
                                    <Row>
                                        <Col md="12">
                                            <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

                                                <Card.Header style={{ backgroundColor: '#07b274' }}>
                                                    <Card.Title as="h4">Fill Details</Card.Title>
                                                    <hr></hr>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form onSubmit={(e) => editTestimonial(e, formData.GlobalReview_ID)}>
                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label htmlFor="name">Name</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="name"
                                                                        value={formData.GlobalReview_Name}
                                                                        onChange={(e) => setFormData({ ...formData, GlobalReview_Name: e.target.value })}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label htmlFor="date">Published Date</label>
                                                                    <Form.Control
                                                                        type="date"
                                                                        name="date"
                                                                        value={formData.GlobalReview_Date}
                                                                        onChange={(e) => setFormData({ ...formData, GlobalReview_Date: e.target.value })}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pr-1 w-full" md="12">
                                                                <Form.Group>
                                                                    <label htmlFor="review">Review</label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        name="review"
                                                                        value={formData.GlobalReview_Review}
                                                                        onChange={(e) => setFormData({ ...formData, GlobalReview_Review: e.target.value })}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="pr-1" md="6">
                                                                <Form.Group>
                                                                    <label htmlFor="rating">Rating</label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name="rating"
                                                                        value={formData.Stars}
                                                                        onChange={(e) => setFormData({ ...formData, Stars: e.target.value })}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className="pl-1" md="6" style={{ display: 'flex', alignItems: 'center' }} >
                                                                <Form.Group>
                                                                    <label style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "30px" }}>Status</label>
                                                                    {/* Use the react-switch for the sliding switch */}
                                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: "50px" }}>
                                                                        <Switch
                                                                            onChange={handleStatusChange}
                                                                            checked={isPublished}
                                                                            onColor="#86d3ff"
                                                                            onHandleColor="#07b274"
                                                                            handleDiameter={20}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                        />
                                                                        <span style={{ marginLeft: '10px' }}>
                                                                            {formData.GlobalReview_Review_Status === "Pending" ? 'Pending' : isPublished ? "Active" : 'InActive'}
                                                                        </span>
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Button
                                                                className="btn-fill pull-right"
                                                                type="submit"
                                                                variant="info"
                                                                style={{ backgroundColor: "#07b274", margin: "20px 0px 0px 20px" }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                            :
                            <span className="d-flex flex-column" >
                                <Row>
                                    <Col lg="4" sm="12">
                                        <Card className={`card-stats ${activeCard === "Pending" ? "active" : ""}`}
                                            onClick={() => setActiveCard("Pending")}
                                            style={{ borderBottom: activeCard === "Pending" ? "4px solid #07b273" : "" }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs="4">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="nc-icon nc-chart text-warning"></i>
                                                        </div>
                                                    </Col>
                                                    <Col xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">Pending Testimonials</p>
                                                            <p>{testimonialsPendingCount}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg="4" sm="12">
                                        <Card className={`card-stats ${activeCard === "Active" ? "active" : ""}`}
                                            onClick={() => setActiveCard("Active")}
                                            style={{ borderBottom: activeCard === "Active" ? "4px solid #07b273" : "" }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs="4">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="nc-icon nc-chart text-warning"></i>
                                                        </div>
                                                    </Col>
                                                    <Col sm="7">
                                                        <div className="numbers">
                                                            <p className="card-category">Active Testimonials</p>
                                                            <p>{testimonialsActiveCount}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg="4" sm="12">
                                        <Card className={`card-stats ${activeCard === "InActive" ? "active" : ""}`}
                                            onClick={() => setActiveCard("InActive")}
                                            style={{ borderBottom: activeCard === "InActive" ? "4px solid #07b273" : "" }}>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs="4">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="nc-icon nc-chart text-warning"></i>
                                                        </div>
                                                    </Col>
                                                    <Col sm="7">
                                                        <div className="numbers">
                                                            <p className="card-category">InActive Testimonials</p>
                                                            <p>{testimonialsInActiveCount}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <div>
                                    <Button className="btn btn-lg  float-right p-2" onClick={() => handleshowform("Add")}>Add Testimonial</Button>
                                </div>
                                <Card className="stripped-tabled-with-hover"> {/* Correct the class name */}
                                    {/* <div></div> */}

                                    <Card.Header style={{ paddingTop: "5px" }}>
                                        <Card.Title as="h4">
                                            <Form className="d-flex">
                                                <Form.Control
                                                    type="search"
                                                    placeholder="Search"
                                                    className="mx-2"
                                                    aria-label="Search"
                                                    value={globalFilter || ''}
                                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                                />
                                            </Form>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className="table-full-width table-responsive px-0">
                                        <Table className="table-hover table-striped" {...getTableProps()}>
                                            <thead>
                                                {headerGroups.map(headerGroup => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column) => (
                                                            <th {...column.getHeaderProps(column.getSortByToggleProps(), {
                                                                style: { width: column.width },
                                                            })}>
                                                                {column.render("Header")}
                                                                {/* Add a sort direction indicator */}
                                                                <span>
                                                                    {column.isSorted
                                                                        ? column.isSortedDesc
                                                                            ? " 🔽"
                                                                            : " 🔼"
                                                                        : ""}
                                                                </span>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>

                                            <tbody {...getTableBodyProps()}>
                                                {tablePage.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map((cell) => (
                                                                <td {...cell.getCellProps({
                                                                    style: {
                                                                        width: cell.column.width,
                                                                    },
                                                                })}>{cell.render("Cell")}</td>
                                                            ))}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                    <PaginationSection
                                        pageIndex={pageIndex}
                                        pageCount={pageOptions.length}
                                        canPreviousPage={canPreviousPage}
                                        canNextPage={canNextPage}
                                        gotoPage={gotoPage}
                                        previousPage={previousPage}
                                        nextPage={nextPage}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        pageOptions={pageOptions}
                                    />
                                </Card>
                            </span>

                    }

                </Col>
            </Row>
        </Container>
    );
}

export default Testimonial;

