import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import { useHistory } from "react-router-dom";
import JobDetails from "./Jobdetailsss";
import { BsArrowLeftShort } from "react-icons/bs"
import PaginationSection from "./PaginationSection";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import moment from 'moment';

import ReactPaginate from 'react-paginate';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";



function ResolvedDispute() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [allDetails, setallDetails] = useState([]);
    const history = useHistory();

    const getDisputeByStatus = async () => {
        try {
            const { data } = await axios.post(`${endPointUrl}/getDisputeByStatus`, { Status: "Resolved" })
            if (data.status) {
                setallDetails(data.data)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect((item) => {
        getDisputeByStatus()
    }, [])

    const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Title",
                accessor: "JobTitle",
            },
            {
                Header: "Raised BY",
                accessor: "RaisedBy",
            },
            {
                Header: "Freelancer",
                accessor: row => `${row["freelancer.Name"]} ${row["freelancer.Last_Name"]}`,
            },
            {
                Header: "Employer",
                accessor: row => `${row["employer.Name"]} ${row["employer.Last_Name"]}`,
            },
            {
                Header: "Status",
                accessor: "Status",
            },
            {
                Header: "Action",
                accessor: "id",
                Cell: ({ row }) => (
                    <div>
                        <span
                            onClick={() => handleclick(row.original.id)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <Button style={{ fill: "#07b274" }}>Show More</Button>
                        </span>
                    </div>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        pageCount,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;
    const JobSelect = (Id) => {
        setID(Id);
        let select = allDetails.filter((item) => {
            // console.log(cell);
            return (item.id === Id);
        })
        setjob(select);
        console.log(job)
    };

    const handleclick = (Id) => {
        history.push(`/admin/Dispute-Details/${Id}`);
    };

    return (
        <>

            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Search"
                                            className="mx-2"
                                            aria-label="Search"
                                            value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                                        />
                                    </Form>

                                </Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped " {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render("Header")}
                                                        {/* Add a sort direction indicator */}
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? " 🔽"
                                                                    : " 🔼"
                                                                : ""}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr  {...row.getRowProps()}>
                                                    {/* {console.log(row.original.id)} */}
                                                    {row.cells.map((cell) => {
                                                        //  const { column } = cell.value;
                                                        //  let Id = column.Name;
                                                        //  console.log(cell.value);

                                                        return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)

                                                    })}
                                                </tr>
                                            );
                                        }

                                        )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <PaginationSection
                                pageIndex={pageIndex}
                                pageCount={pageOptions.length}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                gotoPage={gotoPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pageOptions={pageOptions}
                            />
                        </Card>

                    </Col>
                </Row>

            </Container>

        </>
    );
}

export default ResolvedDispute;