import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import "assets/css/demo.css"
import MyContext from "MyContext";
import { BsArrowLeftShort } from "react-icons/bs"
import Profile from "assets/img/default-avatar.png"
import { useLocation } from "react-router-dom";
import Master from "./Masterss";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';

import { Collapse } from "antd";
const { Panel } = Collapse;

import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";


const Projectdetailsss = () => {
  const { endPointUrl, Token } = useContext(MyContext);

  const [items, setitems] = useState({});
  const [detailview, setdetailview] = useState(false);
  const [user, setuser] = useState({});
  const location = useLocation();
  const [bidder, setbidder] = useState({});
  const [milestone, setmilestone] = useState([]);

  const Id = location.pathname.split('/').pop();

  const getBidByID = async (Id) => {
    console.log(Id);
    try {
      const { data } = await axios.post(endPointUrl + "/user/getBidByID", {
        Bid_ID: Id,
        Token,
      });
      setitems(data.data);
      setuser(data.data.user);
      //   setbidder(data.projectInfo.freelancers);
      //   if (data.projectInfo.Proj.length !== 0) {
      //     setmilestone(data.projectInfo.Proj);
      //   }

      console.log(data.projectInfo.Proj);
      // console.log(data.projectInfo);
    } catch {
      console.log("get all details projects is not working");
    }
  };


  const getMilestonForBid = async (Id) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getMilestoneforBid", { Token, Bid_ID: Id })
      console.log('milestion bid',data);
      setmilestone(data.milestones);
    } catch (error) {
      console.log("get all details projects is not working");
    }
  }

  useEffect(() => {
    getBidByID(Id);
    getMilestonForBid(Id)
  }, []);

  const Active = items?.Bid_Status === "Active";
  const Closed = items?.Bid_Status === "Closed";
  console.log(items)


  return (
    <>
      <Container fluid>
        <div className="container-fluid d-flex justify-content-between">
          <h6 className="heading-6" style={{ padding: "20px 0 20px 0" }}>Bid Details </h6>
        </div>
        <Row>
          <Col md="8">
            {/* <Card className="`card-user`">
              <Card.Header style={{backgroundColor:'#07b274'}}>
                <Card.Title as="h4">{items?.jobs?.Title}</Card.Title>
              <hr></hr>
              </Card.Header> */}
            <Card className='card-user' style={{ margin: "2% auto", padding: "20px", border: "1px solid #888" }}>

              <Card.Header style={{ backgroundColor: '#07b274' }}>
                <Card.Title as="h4">{items?.freelancerBidjobs?.Title}</Card.Title>
                <hr></hr>
              </Card.Header>
              <Card.Body>

                <div className="about">
                  <span>{items?.jobs?.ShortDesc}</span>
                </div>
                <hr></hr>
                <div className="about">
                  <h6>Proposal</h6>
                  <span dangerouslySetInnerHTML={{ __html: items?.Proposal }}></span>
                </div>
              </Card.Body>
            </Card>


            <Card className="card-user " style={{ margin: "2% auto", padding: "40px", border: "1px solid #888" }}>

              <div className="biddiv" >
                {/* <div style={{flex:'1'}} className="card-image profile">
           
            
             */}
                {/* </div> */}
                <div className="job-name" style={{ flex: '5', fontWeight: '600', fontSize: '30px', padding: "30px" }}>
                  <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <img
                      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                      src={
                        bidder?.Photo === "" || bidder?.Photo == null
                          ? Profile
                          : `${endPointUrl}/images/${user?.Photo}`
                      }
                      alt="profile" />
                    <p className="fw-bold m-0">{bidder.Name}</p>
                  </span>

                  <div className="current-tags">
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                      if (index < 3) {
                        return val.length > 0 ? <span>{val}</span> : null
                      }
                    })}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 3 ? "+" : ""}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 3 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 3 : ""}

                  </div>
                </div>
                <div className="biddingInformation" style={{ flex: '2' }}>
                  <div id="bidheadStatus">
                    {/* {Ongoing && (
                      <div>
                        <p>Ongoing</p>
                      </div>
                    )}
                    {Closed && (
                      <div>
                        <p>Closed</p>
                      </div>
                    )} */}
                  </div>

                  <div>
                    <span className="bidhead">Price</span>
                    <span id="bidheadValue">${items?.BidAmount}</span>
                  </div>
                  <div>
                    <span className="bidhead">Duration</span>
                    <span id="bidheadDuration">
                      {items?.Duration + "/"}
                      {items?.DurationType}
                    </span>
                  </div>
                </div>


              </div>
              {/* <b style={{ marginTop: "10px" }}>Proposal</b>
              <span dangerouslySetInnerHTML={{ __html: items?.bid?.Proposal }} className="biddingProposal"></span> */}
              <Master milestone={milestone} />



            </Card>
          </Col >
          <Col md="4" style={{ marginTop: "15px" }}>
            <div className="compo2">
              <div className="profileConatiner">
                <div className="profile-details">

                  <img
                    src={
                      user?.Photo === "" || user?.Photo == null
                        ? Profile
                        : `${endPointUrl}/images/${user?.Photo}`
                    }
                    alt=""
                  // style={{width:'20%',display:"flex"}}
                  />
                  <span className="profilename">{user?.Name}</span>
                  <span className="profileDesignation">
                    {user?.Company}
                  </span>
                </div>
              </div>


              {/* <div className="row-view"> */}
              <div className="skillsset-card" style={{ marginTop: "10px" }}>
                <h5>Budget Details</h5>
                <small>
                  <span>{items?.freelancerBidjobs?.Budget_From}-</span>
                  <span>{items?.freelancerBidjobs?.Budget_To}/</span>
                  <span>{items?.freelancerBidjobs?.Budget_Type}</span>
                </small>
              </div>
              <hr style={{ width: "100%", background: "" }} />
              <div className="skillsset-card">
                {" "}
                <h5>Job Type</h5>
                <small>{items?.freelancerBidjobs?.Type}</small>
              </div>
              <hr style={{ width: "100%", background: "" }} />

              <div className="skillsset-card">
                {" "}
                <h5>Project Type</h5>
                <small>{items?.freelancerBidjobs?.ProjectType}</small>
              </div>
              <hr style={{ width: "100%", background: "" }} />

              {/* <div className="skillsset-card" >

                <h5>Finance</h5>
                <ul>
                  <li>Paid Amount : ${items?.paidPayment}</li>
                  <li>Due Amount : ${items?.duePayment}</li>
                  <li>Total Amount : ${items?.TotalPayment}</li>
                </ul>
              </div> */}

              <hr style={{ width: "100%", background: "" }} />
              <div className="skillsset-card">
                {" "}
                <h5>Skills</h5>
                <div className="skillview">
                  {((items?.freelancerBidjobs?.SkillsRequired) ? items?.freelancerBidjobs?.SkillsRequired : '').split(",")?.map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>Technology</h5>
                <div className="skillview">
                  {((items?.freelancerBidjobs?.Technology) ? items?.freelancerBidjobs?.Technology : '').split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>Category</h5>
                <div className="skillview">
                  {((items?.freelancerBidjobs?.Category) ? items?.freelancerBidjobs?.Category : '').split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>Language</h5>
                <div className="skillview">
                  {((items?.freelancerBidjobs?.Language) ? items?.freelancerBidjobs?.Language : '').split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
              <div className="skillsset-card">
                <h5>KeyWords</h5>
                <div className="skillview" style={{ marginBottom: "20px" }}>
                  {(items?.freelancerBidjobs?.Keywords)?.split(",").map((item) => (
                    <span>{item}</span>
                  ))}
                </div>
              </div>
            </div>



            {/* </Card> */}
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default Projectdetailsss;