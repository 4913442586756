import React, { useState, useContext, useEffect } from "react";
import remove from '../assets/img/delete.png';
import add from '../assets/img/add.png'
import moment from "moment/moment";
import { message } from 'antd';
import MyContext from "MyContext";
import axios from 'axios';

function Milestone({ forms, setForms, detailjob, Bid_ID }) {
  const { user, Token, endPointUrl, socket } = useContext(MyContext);
  const [Index, setIndex] = useState();
  const [errorForm, setErrorForm] = useState({ Amount: "", Description: "", M_Due_Date: "", status: "" },);

  const getIndex = () => {
    for (let index = 0; index < forms.length; index++) {
      const item = forms[index];
      if (item.status === "Pending" || item.status === "Rejected") {
        setIndex(index);
        break;
      }
    }
  };


  const handleFormChange = (index, event) => {
    const { name, value } = event.target;
    const newForms = [...forms];
    if (name === "M_Due_Date" && index == 0 && String(new Date(value).getFullYear()).length == 4 && new Date(value).getFullYear() < new Date().getFullYear() && new Date(value) < new Date()) {
      message.warning("Due date should be greater than the Current Date");
      return;
    }
    else if (name === "M_Due_Date" && index > 0 && String(new Date(value).getFullYear()).length == 4) {
      const previousDueDate = newForms[index - 1].M_Due_Date;
      if (new Date(value) <= new Date(previousDueDate)) {
        // Display an error message or handle the validation accordingly
        message.warning("Due date must be greater than the previous one");
        return;
      }
    }

    if (name === "Amount")
      newForms[index][name] = value.slice(0, 6);
    else
      newForms[index][name] = value;

    setForms(newForms);
  };

  const handleAddForm = () => {
    // Validation logic for the last form in the array
    const lastForm = forms[forms.length - 1];
    const newFormErrors = { Amount: "", Description: "", M_Due_Date: "" };
    let showError = false;

    // Check if the last form fields are not empty
    if (lastForm.Amount === 0 || lastForm.Amount === "") {
      showError = true;
      newFormErrors.Amount = 'Please fill out this field!';
    }
    if (lastForm.Description === "") {
      showError = true;
      newFormErrors.Description = 'Please fill out this field!';
    }
    if (lastForm.M_Due_Date === "") {
      showError = true;
      newFormErrors.M_Due_Date = 'Please fill out this field!';
    }

    // Update the error state
    setErrorForm(newFormErrors);

    // If there are no errors, add a new form
    if (!showError) {
      const newForms = [
        ...forms,
        { Amount: 0, Description: "", M_Due_Date: "" },
      ];
      setForms(newForms);
    }
  };


  const handleRemoveForm = (index) => {
    const newForms = [...forms];
    newForms.splice(index, 1);
    setForms(newForms);
  };

  useEffect(() => {
    getIndex();
  }, [forms])

  return (
    <>
      <div>
        <h4>{detailjob.ProjectType === "Hourly" ? "" : "Milestones"}</h4>
        {detailjob.ProjectType === "Hourly" ? <></> :
          forms.map((form, index) => (
            <div key={index}>
              #{index + 1}
              <div className="bid_Form milestone_Form" >
                <label style={{ position: "relative" }}>
                  Amount<span className="asterisk">*</span>
                  <input
                    type="number"
                    name="Amount"
                    step={0.01}
                    value={form.Amount}
                    onChange={(event) => {
                      const { value } = event.target;
                      if (value >= 0 || value === '') {
                        handleFormChange(index, event);
                      }
                    }}
                  />
                  <small style={{ color: "#ec1c24" }}>{errorForm.Amount}</small>
                </label>

                <label style={{ position: "relative" }}>
                  Due_Date<span className="asterisk">*</span>
                  <input
                    type="date"
                    name="M_Due_Date"
                    value={moment(form.M_Due_Date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}
                    onChange={(event) => handleFormChange(index, event)}
                    min={moment().format("YYYY-MM-DD")}
                  />
                  <small style={{ color: "#ec1c24" }}>{errorForm.M_Due_Date}</small>
                </label>

              </div>
              <div className="bid_Form">
                <label className="description" style={{ position: "relative" }}>
                  Description<span className="asterisk">*</span>
                  <textarea
                    name="Description"
                    id="Description"
                    cols="40"
                    rows="5"
                    value={form.Description}
                    onChange={(event) => handleFormChange(index, event)}
                  ></textarea>
                  <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                </label>

              </div>
              <hr />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {(index > 0) && (
                  <img src={remove} onClick={() => handleRemoveForm(index)} className="remove" />
                )}
                {(index === forms.length - 1) && (
                  <img src={add} onClick={handleAddForm} className="add" />
                )}
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
}

export default Milestone;